.ccccn .bottom-buttons-icon-color {
  color: #fff;
  height: 24px;
  width: 24px;
}
.ccccn .bottom-buttons-icon-button {
  border: none;
  outline: none;
  margin-right: 4px;
  position: absolute;
  right: -10px;
  bottom: -14px;
}
.ccccn .cc-bottom-buttons-icon-container {
  min-height: 50px;
  min-width: 50px;
  height: 7vh;
  width: 7vh;
  background-color: #31383e;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ccccn .cc-bottom-buttons-with-padding {
  height: 0px;
  /* width: 100vw; */
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 0px;
  background-image: linear-gradient(rgb(28, 34, 38), rgb(19, 23, 26));
  padding-right: 20vh;
}

@media (max-width: 600px) {
  .ccccn .cc-bottom-buttons-with-padding {
    padding-right: 0px;
  }
}

.ccccn .cc-bottom-buttons {
  height: var(--ccccn-bottom-button-container-height);
  /* width: 100vw; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(rgb(28, 34, 38), rgb(19, 23, 26));
  position: relative;
}

.ccccn .bottom-buttons-other-options {
  position: absolute;
  display: flex;
  right: 8px;
  bottom: 8px;
}

.ccccn .dialog-form {
  padding: 16px;
}
.ccccn .settings-dropdown-style {
  min-width: 250px;
  max-width: 250px;
  width: 250px;
}

.ccccn .settings-icon-button {
  border: none;
  outline: none;
}

.ccccn .color-white {
  color: #fff;
}

.ccccn .setting-menu-icon-container {
  margin-right: 16px;
}

.ccccn .track-error-container {
  position: absolute;
  bottom: 0;
  right: -4px;
  background-color: #31383e;
  border-radius: 50%;
  aspect-ratio: 1px;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ccccn .track-error-icon {
  aspect-ratio: 1px;
  height: 16px;
  width: 16px;
  color: #fff;
}

.ccccn .cc-audio-icon-container-muted {
  background-color: #d93438;
}

.ccccn .cc-end-call-icon-container {
  background-color: #d93438;
}

.ccccn .cc-video-icon-container-muted {
  background-color: #d93438;
}
